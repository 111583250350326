import { useCallback, useState } from 'react';
import KwikCheckoutScript from './KwikCheckoutScript';
import CheckoutEvents from './CheckoutEvents';

export default function KwikCheckout() {
    const [scriptLoaded, setScriptLoaded] = useState(false)
    const onLoaded = useCallback(() => {
        setScriptLoaded(true)
    }, [])

    return (
        <>
            <KwikCheckoutScript onLoaded={onLoaded}/>
            {scriptLoaded && <CheckoutEvents />}
        </>
    );
}