import {
  NEXT_ADD_TO_CART,
  NEXT_GET_CART,
  NEXT_GET_USER_CART,
  NEXT_ASSOCIATE_CART,
  NEXT_BASKET_VALIDATE,
  EmptyGuid,
  FirebaseKeys,
} from '@components/utils/constants'
import axios from '@services/axiosInterceptorInstance'
import eventDispatcher from '@components/services/analytics/eventDispatcher'
import { EVENTS_MAP } from '@components/services/analytics/constants'
import { BasketServices } from 'services/basket/index.service'
import { applyHandlingFee, getRemoteConfigAsBoolean } from '@framework/utils/app-util'

interface CartItem {
  basketId?: string
  productId?: string
  qty: number
  productSpecification?: string
  displayOrder?: number
  stockCode?: string
  userId?: string
  isAssociated?: boolean
  CustomInfo4?: string
  CustomInfo5?: string
  CustomInfo4Formatted?: string
  CustomInfo5Formatted?: string
  isChannelStore?: boolean
  BasketName?: string
  StoreId?: string
}

interface GetCart {
  basketId?: string
  isChannelStore?: boolean
}

const { BasketItemAdded, BasketItemRemoved, BasketViewed } =
  EVENTS_MAP.EVENT_TYPES

export default function cartHandler() {
  return {
    addToCart: async (
      {
        basketId,
        productId,
        qty,
        productSpecification,
        displayOrder,
        stockCode,
        userId,
        isAssociated = true,
        CustomInfo4,
        CustomInfo5,
        CustomInfo4Formatted,
        CustomInfo5Formatted,
        isChannelStore,
        BasketName,
        StoreId,
      }: CartItem,
      type = 'ADD',
      data: any = {},
      addToCartCallback: any = null
    ) => {
      // let response: any = await axios.post(NEXT_ADD_TO_CART, {
      //   data: {
      //     basketId,
      //     productId,
      //     qty,
      //     productSpecification,
      //     displayOrder,
      //     stockCode,
      //     CustomInfo4,
      //     CustomInfo5,
      //     CustomInfo4Formatted,
      //     CustomInfo5Formatted,
      //     BasketName,
      //     StoreId,
      //     // isChannelStore: isChannelStore ? true : false,
      //   },
      // })
      let response: any = await BasketServices.addToBasket({
        // data: {
        basketId,
        productId,
        qty,
        productSpecification,
        displayOrder,
        stockCode,
        customInfo4: CustomInfo4,
        customInfo5: CustomInfo5,
        customInfo4Formatted: CustomInfo4Formatted,
        customInfo5Formatted: CustomInfo5Formatted,
        basketName: BasketName,
        storeId: StoreId,
        // isChannelStore: isChannelStore ? true : false,
        // },
      })
      if (userId && !isAssociated) {
        response = await cartHandler().associateCart(
          userId,
          basketId,
          isChannelStore ? true : false
        )
      }

      if(getRemoteConfigAsBoolean(FirebaseKeys.ADD_CONVENIENCE_FEE) && !isChannelStore){
        const requestData = { 
          basketId, 
          promoCode : 'CONV-FEES', 
        }
        const result:any = await applyHandlingFee(requestData)
        if(result){
          response.data = result
        }
      }
      if (addToCartCallback) {
        addToCartCallback(response?.data)
      }

      const { data: validateResult }: any = await cartHandler().validateBasket(
        basketId,
        isChannelStore ? true : false
      )

      const eventData = {
        entity: JSON.stringify({
          basketId,
          id: productId,
          name: data?.product?.name,
          price: data?.product?.price?.raw?.withTax,
          quantity: qty,
          stockCode: data?.product?.stockCode,
          CustomInfo4: CustomInfo4,
          CustomInfo5: CustomInfo5,
          CustomInfo4Formatted: CustomInfo4Formatted,
          CustomInfo5Formatted: CustomInfo5Formatted,
        }),
        basketItems: JSON.stringify(
          response.data?.lineItems?.map((obj: any) => {
            return {
              basketId,
              id: obj.id,
              img: obj.image,
              name: obj?.name,
              price: obj.price?.raw?.withTax,
              qty: obj.qty,
              stockCode: obj.stockCode,
              tax: obj.price.raw.tax,
              CustomInfo4: CustomInfo4,
              CustomInfo5: CustomInfo5,
              CustomInfo4Formatted: CustomInfo4Formatted,
              CustomInfo5Formatted: CustomInfo5Formatted,
            }
          })
        ),
        basketItemCount: response.data?.lineItems?.length || 0,
        basketTotal: response.data.grandTotal?.raw?.withTax,
        entityId: data.product.recordId,
        entityType: 'product',
        eventType: BasketItemAdded,
        entityName: data.product.name,
      }

      if (qty && qty > 0) {
        eventDispatcher(BasketItemAdded, eventData)
      } else
        eventDispatcher(BasketItemRemoved, {
          ...eventData,
          eventType: BasketItemRemoved,
        })

      const result = {
        ...response.data,
        ...{ basketValidate: validateResult?.result },
      }
      return result
    },
    getCart: async ({ basketId, isChannelStore }: GetCart) => {
      const apiUrl = isChannelStore
        ? `${NEXT_GET_CART}?basketId=${basketId}&isChannelStore=${isChannelStore}`
        : `${NEXT_GET_CART}?basketId=${basketId}`
      const response = await axios.get(apiUrl)
      return response.data
    },
    associateCart: async (
      userId: string,
      basketId?: string,
      isChannelStore?: boolean
    ) => {
      const response = await axios.post(NEXT_ASSOCIATE_CART, {
        data: { userId: userId, basketId: basketId },
        isChannelStore: isChannelStore ? true : false,
      })
      return response
    },
    validateBasket: async (basketId?: string, isChannelStore?: boolean) => {
      const response = await axios.post(NEXT_BASKET_VALIDATE, {
        basketId: basketId,
        isChannelStore,
      })
      return response
    },
    getCartByUser: async ({ userId, basketId }: any) => {
      if (userId && userId !== EmptyGuid) {
        try {
          const { data: userCart }: any = await axios.get(NEXT_GET_USER_CART, {
            params: {
              userId: userId,
            },
          })

          if (userCart?.length) {
            return userCart?.sort((cart1: any, cart2: any) => {
              return (
                new Date(cart2?.lastUpdated).getTime() -
                new Date(cart1?.lastUpdated).getTime()
              )
            })[0]
          }
        } catch (error) {
          console.error(':: error', error)
        }
      }
      return null
    },
  }
}
