import Script from "next/script"
import { KP_MERCHANT_SCRIPT_BASE_URL } from '@framework/utils/constants'

const KwikCheckoutScript = ({ onLoaded }: any) => {
    const onReady = () => {
        if (onLoaded) onLoaded();
    }
    return <Script defer strategy="afterInteractive" src={`${KP_MERCHANT_SCRIPT_BASE_URL}v4/build/gokwik.js`} onReady={onReady} />
}

export default KwikCheckoutScript;