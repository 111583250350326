import { useEffect } from "react";
import Router from 'next/router'
import { handlePurchaseGAEvent } from '@components/cart/CartSidebarView/eventsHelper';
import { getUserId } from '@components/cart/CartSidebarView/helper';
import { selectPromotionGAEvent } from '@components/cart/Promotions/helper';
import { beginCheckout, getPaymentFailurePayload, getShippingInfoPayload } from '@components/MobileCart/helper';
import { recordGA4Event } from '@components/services/analytics/ga4';
import { EmptyGuid, EmptyString, FirebaseKeys } from '@components/utils/constants';
import { getItem } from '@components/utils/localStorage';
import { LocalStorage } from '@components/utils/payment-constants';
import { generateCodState, getDefaultPaymentMethod, getKwikPassFields, getRemoteConfigAsBoolean, getRemoteConfigAsString, loginUserByToken } from '@framework/utils/app-util';
import { encrypt, getEncryptedNumber } from '@framework/utils/cipher';
import { matchStrings } from '@framework/utils/parse-util';
import { GA_EVENT } from 'hooks/ga_event.modal';
import { useMoEngageEvent } from 'hooks/useMoengageEvent';
import { ICodState } from "@components/checkout/CheckoutPayment/Components/PaymentsIndexSection";


const CheckoutEvents = () => {
    const trackMoEngageEvent = useMoEngageEvent()
    let cartItems:any = { lineItems: [] }
    let user:any = {}
    let isGuestUser = false

    const handleCheckoutReady = (data: any) => {
        cartItems = getItem(LocalStorage.Key.CART_ITEMS)
        isGuestUser = getItem(LocalStorage.Key.IS_GUEST)
        user = getItem(LocalStorage.Key.USER)
        let preferredPaymentMethod: string | undefined = undefined
        const userId = getUserId(user, cartItems)
        if (isGuestUser || (userId && matchStrings(userId, EmptyGuid, true))) {
            preferredPaymentMethod = ''
        } else {
            preferredPaymentMethod = getDefaultPaymentMethod()
        }
        beginCheckout(cartItems, user, trackMoEngageEvent, preferredPaymentMethod)
        console.log("checkout-ready::", data);
    };

    const handleUserLoginInitiated = (data: any) => {
        console.log("user-login-initiated::", data);
        const mobileRSA = getEncryptedNumber(data?.phoneNumber)
        const gaPayload = {
            ...getKwikPassFields(),
            mobile_number: mobileRSA,
            previous_page: 'Cart',
            address_source: 'goKwik',
            user_type: data?.user_type,
            login_popup: 'NA',
        };

        // GA event for OTP request
        recordGA4Event(window, GA_EVENT.OTP_REQUEST, gaPayload);
    };

    const handleUserLoginSuccessful = async (data: any) => {
        console.log("user-login-successful::", data);
        const mobileRSA = getEncryptedNumber(data?.phone_no)
        const gaPayload = {
            ...getKwikPassFields(),
            mobile_number: mobileRSA,
            previous_page: 'Cart',
            address_source: 'goKwik',
            user_type: data?.user_type,
            login_popup: 'NA',
        };
        // GA event for OTP submit
        recordGA4Event(window, GA_EVENT.OTP_SUBMIT, gaPayload);

        // Login user from login Token
        // loginUserByToken(data?.loginToken, trackMoEngageEvent)
    };

    const handleAddressAdd = (data: any) => {
        console.log("address-add::", data);
        cartItems = getItem(LocalStorage.Key.CART_ITEMS)
        user = getItem(LocalStorage.Key.USER)
        let prevPage = "cart"
        let addressIdx = 0
        const gaPayload = getShippingInfoPayload(user, cartItems, prevPage, addressIdx)
        recordGA4Event(window, GA_EVENT.ADD_SHIPPING_INFO, gaPayload);
    };

    const handleAddressSelected = (data: any) => {
        console.log("address-selected::", data);
    };

    const handleCouponApplied = (data: any) => {
        cartItems = getItem(LocalStorage.Key.CART_ITEMS)
        user = getItem(LocalStorage.Key.USER)
        console.log("coupon-applied::", data);
        selectPromotionGAEvent(cartItems, data?.coupon_code, data?.coupon_code, user)
    };

    const handleCouponRemoved = (data: any) => {
        console.log("coupon-removed::", data);
    };

    const handleCouponInvalid = (data: any) => {
        console.log("coupon-invalid::", data);
    };

    const handlePaymentMethodSelected = (data: any) => {
        console.log("payment-method-selected::", data);
        user = getItem(LocalStorage.Key.USER)
        const gaPayload = {
            mode_type: data?.payment_method,
            loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
            address_source: 'GoKwik',
            enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
        };
         recordGA4Event( window, GA_EVENT.CHECKOUT_PAYMODE, gaPayload )
    };

    const handlePaymentFailure = (data: any) => {
        console.log("payment-failure::", data);
        cartItems = getItem(LocalStorage.Key.CART_ITEMS)
        const gaPayload = getPaymentFailurePayload(cartItems)
        recordGA4Event(window, GA_EVENT.FAILED_TRANSACTION, gaPayload)
    };

    const handleOrderComplete = (data: any) => {
        window.gokwikSdk.close();
        console.log("order-complete::", data);
        cartItems = getItem(LocalStorage.Key.CART_ITEMS)
        user = getItem(LocalStorage.Key.USER)
        const getCodState: ICodState = generateCodState(null)
        const encryptedCodState = encrypt(JSON.stringify(getCodState))
        localStorage.setItem(LocalStorage.Key.COD_STATE, encryptedCodState)
        Router.replace({
            pathname: '/thank-you',
            query: {
              oId: btoa("80004132"),
              goKwik: true
            },
        })
        // need to change for Live Order
        const payment = { 
            id : data?._id,
            orderAmount : data?.total,
            orderNo : data?.gokwik_order_id,
        }
        handlePurchaseGAEvent(payment, cartItems, user, true, [])
    };

    const handleCheckoutClose = (data: any) => {
        console.log("checkout-close::", data);
        cartItems = getItem(LocalStorage.Key.CART_ITEMS)
        const gaPayload = getPaymentFailurePayload(cartItems)
        recordGA4Event(window, GA_EVENT.FAILED_TRANSACTION, gaPayload)
    };

    const handlePaymentNavigation = (data: any) => {
        console.log("payment-navigation::", data);
        user = getItem(LocalStorage.Key.USER)
        const gaPayload = {
            enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
            mode_type: data?.map((x: any) => x?.methodType)?.join(',') || EmptyString,
            loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
            address_source: 'GoKwik',
        };
        recordGA4Event(window, GA_EVENT.PAYMENT_NAVIGATION, gaPayload)
    };

    const handleCheckoutInitiationFailure = (data: any) => {
        console.log("checkout-initiation-failure::", data);
        const gaPayload = {
            enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
        };
        recordGA4Event(window, GA_EVENT.CHECKOUT_INITIATION_FAILURE, gaPayload)
    };

    useEffect(() => {
        if (window.gokwikSdk) {
            window.gokwikSdk.on("checkout-ready", handleCheckoutReady);
            window.gokwikSdk.on("user-login-initiated", handleUserLoginInitiated);
            window.gokwikSdk.on("user-login-successful", handleUserLoginSuccessful);
            window.gokwikSdk.on("address-add", handleAddressAdd);
            window.gokwikSdk.on("address-selected", handleAddressSelected);
            window.gokwikSdk.on("coupon-applied", handleCouponApplied);
            window.gokwikSdk.on("coupon-removed", handleCouponRemoved);
            window.gokwikSdk.on("coupon-invalid", handleCouponInvalid);
            window.gokwikSdk.on("payment-method-selected", handlePaymentMethodSelected);
            window.gokwikSdk.on("payment-failure", handlePaymentFailure);
            window.gokwikSdk.on("order-complete", handleOrderComplete);
            window.gokwikSdk.on("checkout-close", handleCheckoutClose);
            window.gokwikSdk.on("payment-navigation", handlePaymentNavigation);
            window.gokwikSdk.on("checkout-initiation-failure", handleCheckoutInitiationFailure);
        }
    }, []);

    return null; // This component doesn't render anything
};

export default CheckoutEvents;
