import Router from 'next/router'
import { variantProductFilter } from '@components/cart/CartSidebarView/helper'
import { recordGA4Event } from '@components/services/analytics/ga4'
import cartHandler from '@components/services/cart'
import {
  AppsFlyerKeys,
  AppsFlyerParams,
  BETTERCOMMERCE_OMS_ENABLED,
  CartAction,
  EmptyGuid,
  FacebookEvents,
  FirebaseKeys,
  Messages,
  NEXT_GET_JUSPAY_SAVED_UPIS,
  NEXT_GET_PRODUCT,
  NEXT_SHIPPING_PLANS,
  PRODUCTS_SLUG_PREFIX,
  SHORT_CLOSE_TIMER,
  USER_TOKEN_ERROR,
} from '@components/utils/constants'
import { getItem } from '@components/utils/localStorage'
import { LocalStorage } from '@components/utils/payment-constants'
import {
  FREE_SHIPPING,
  GENERAL_ADD_TO_BASKET,
  GENERAL_CHANGE_SIZE,
  GENERAL_PRICE_DETAIL,
  GENERAL_PRICE_INC_TAX,
  GENERAL_SELECT_SIZE,
  GENERAL_SHIPPING,
  NETWORK_ERR,
  PROD_ADDED,
  PROD_ADDED_SUCCESSFULLY,
  STATIC_DELIVERY_FEE,
} from '@components/utils/textVariables'
import {
  eventIdForFB,
  getAddressSource,
  getBrowserIdForFBConversion,
  getCategories,
  getColor,
  getColorAndSize,
  getCommonAppsFlyerValue,
  getCurrentPage,
  getDateOfBirthHash,
  getGenderHash,
  getIpAddressForFBConversion,
  getKwikPassFields,
  getlineItemSizeWithoutSlug,
  getRemoteConfigAsBoolean,
  isCartAssociated,
  resetSubmitData,
  submitData,
  validateAddToCart,
} from '@framework/utils/app-util'
import { getEncryptedNumber, getSHA256Hash } from '@framework/utils/cipher'
import { matchStrings, priceFormat } from '@framework/utils/parse-util'
import axios from '@services/axiosInterceptorInstance'
import { facebookEventHandler } from 'hooks/facebook-conversion'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { InfoIcon } from '@components/icons'
import Cookies from 'js-cookie'
import { getSessionItem } from '@components/utils/sessionStorage'
import { getUserNumber } from '@components/account/loginHelper'

declare const AF: any

interface IFetchShippingPlans {
  cart: any
  setCartItems: any
  isChannelStore: boolean
  storeBasketId: any
  basketId: any
}

interface IGeneratePriceDetailsSection {
  totalMRP: number
  discountOnMRP: number
  cartItems: any
  freeItem: boolean
  convenienceData?: any
  setConvenienceFee?: any
  isChannelStore?: boolean
}

export const handleEvents = async (
  product: any,
  trackGoogleAnalyticsEvent: any,
  user: any,
  trackMoEngageEvent: any,
  isChannelStore: boolean
) => {
  let item_variant = ''
  if (product?.variantGroupCode) {
    item_variant = product.variantGroupCode.split('-')[1]
  }
  // trackGoogleAnalyticsEvent(
  //   GA_EVENT.ADD_TO_CART,
  //   {
  //     ecommerce: {
  //       items: [
  //         {
  //           item_name: product?.name,
  //           item_brand: product?.brand,
  //           item_category2: getCategories(product)?.category2,
  //           item_category: getCategories(product)?.category,
  //           item_variant: item_variant,
  //           color: item_variant,
  //           quantity: product?.qty,
  //           item_id: product?.sku,
  //           item_size: getlineItemSizeWithoutSlug(product),
  //           price: product?.price?.raw?.withTax,
  //           item_var_id: product?.stockCode,
  //           item_list_name: getCategories(product)?.category,
  //           index: 1,
  //         },
  //       ],
  //       cart_quantity: 1,
  //       total_value: product?.price?.raw?.withTax,
  //       current_page: 'PDP',
  //       section_title: 'Product Detail',
  //       loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
  //     },
  //   },
  //   window
  // )

  if (typeof AF !== 'undefined') {
    const commonEventValue = getCommonAppsFlyerValue()
    AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: AppsFlyerKeys.AddToCart,

      eventRevenueCurrency: 'INR',
      eventValue: {
        [AppsFlyerParams.AFEventParam3]: 'PDP',
        [AppsFlyerParams.AFEventParamRevenue]:
          product?.price?.raw?.withTax + '',
        [AppsFlyerParams.AFEventParamContent]: product?.name,
        [AppsFlyerParams.AFEventParamContentId]: product?.stockCode,
        [AppsFlyerParams.AFEventParamPrice]: product?.price?.raw?.withTax + '',
        [AppsFlyerParams.AFEventParamQuantity]: 1 + '',
        [AppsFlyerParams.AFEventParam5]:
          product?.classification?.mainCategoryName || '',
        [AppsFlyerParams.AFEventParam6]:
          product?.classification?.category || '',
        ...commonEventValue,
      },
    })
  }

  if (!isChannelStore) {
    trackMoEngageEvent(
      MO_ENGAGE_EVENT.ADD_TO_CART,
      {
        Product_size: getlineItemSizeWithoutSlug(product),
        URL: window?.location?.href,
        Cart_quantity: 1,
        Cart_total: product?.price?.raw?.withTax,
        Product_name: product?.name,
        Product_ID: product?.stockCode,
        Price: product?.price?.raw?.withTax,
        label: 'Add To Bag',
        action: 'Click',
        Quantity: 1,
        Image: product?.image,
        Product_category: product?.classification?.mainCategoryName || '',
        Product_category_2: product?.classification?.category || '',
        Product_color: product?.variantGroupCode,
      },
      window
    )
  }
}

export const getDeliveryAddress = () => {
  const address = getItem(LocalStorage.Key.DELIVERY_ADDRESS)
  if (typeof address === 'string') {
    return JSON.parse(address)
  } else if (!!address) {
    return address
  }
  return null
}

const addToCartGAEvent = async (
  trackGoogleAnalyticsEvent: any,
  user: any,
  productData: any
) => {
  const eventId = eventIdForFB()
  trackGoogleAnalyticsEvent(
    GA_EVENT.ADD_TO_CART,
    {
      ecommerce: {
        items: [
          {
            item_name: productData?.name,
            item_brand: productData?.brand,
            item_category2: getCategories(productData).category2,
            item_category: getCategories(productData).category,
            item_variant: getColorAndSize(productData),
            color: getColor(productData),
            quantity: productData?.qty,
            item_id: productData?.sku,
            item_size: getlineItemSizeWithoutSlug(productData),
            price: productData?.price?.raw?.withTax,
            item_var_id: productData?.stockCode,
            item_list_name: getCategories(productData).category,
            index: 1,
          },
        ],
        cart_quantity: 1,
        total_value: productData?.price?.raw?.withTax,
        current_page: getCurrentPage(),
        section_title: 'Quick Add',
        event_id: eventId,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
    },
    window
  )

  const mobileNumber = user?.mobile || user?.telephone
  let facebookeventData = {
    data: [
      {
        event_name: FacebookEvents.ADD_TO_CART,
        event_time: Math.floor(new Date().getTime() / 1000),
        event_id: eventId,
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: null,
          zp: null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          currency: 'INR',
          num_items: 1,
          content_name: getCurrentPage(),
          value: productData?.price?.raw?.withTax,
          content_category: getCategories(productData).category,
          content_ids: [productData?.stockCode],
          content_type: 'product',
          contents: [
            {
              id: productData?.recordId ?? productData?.productId,
              quantity: 1,
              item_price: productData?.price?.raw?.withTax,
              delivery_category: 'home_delivery',
            },
          ],
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)
}

export const buttonTitle = (props: any) => {
  const {
    cartItems,
    selectedProduct,
    variantProducts,
    currentVariantData,
    setAlert,
    submitDispatch,
    previousVariantData,
    handleCloseSizeModal,
    handleUpdateProductItemSize,
    sizeDialogState,
    basketId,
    user,
    setAlertWithLessTimer,
    setCartItems,
    getBasketPromos,
    trackGoogleAnalyticsEvent,
    trackMoEngageEvent,
    storeBasketId,
    isChannelStore,
  } = props

  let buttonConfig: any = {
    buttonTitle: GENERAL_CHANGE_SIZE,
    title: 'Done',
    validateAction: async () => {
      const cartLineItem: any = cartItems?.lineItems?.find(
        (o: any) => o.productId === selectedProduct?.productId?.toUpperCase()
      )
      const variantsThatMatchSize = variantProductFilter(
        variantProducts || [],
        'clothing.size',
        currentVariantData || ''
      )[0]
      if (variantsThatMatchSize?.currentStock < cartLineItem?.qty) {
        handleCloseSizeModal(false)
        setAlert({
          type: 'error',
          msg: Messages.Errors['CART_ITEM_QTY_MAX_ADDED'],
        })
        return false
      }
      const isValid = validateAddToCart(
        selectedProduct?.productId ?? selectedProduct?.recordId,
        cartItems
      )
      if (!isValid) {
        setAlert({
          type: 'error',
          msg: Messages.Errors['CART_ITEM_QTY_LIMIT_EXCEEDED'],
        })
      }
      return isValid
    },
    action: async () => {
      submitData(submitDispatch, CartAction.UPDATE_SIZE)
      const newSize = document
        .querySelector('div.product-sizes div.size-option.border-gray-800')
        ?.attributes.getNamedItem('data-size')?.value
      const variantsThatMatchSize = variantProductFilter(
        variantProducts || [],
        'clothing.size',
        newSize?.toLowerCase()?.replaceAll(' ', '-') || ''
      )[0]
      const newStockCode = variantsThatMatchSize?.stockCode
      if (
        currentVariantData?.toUpperCase() == previousVariantData?.toUpperCase()
      ) {
        handleCloseSizeModal(false)
        return
      }
      await handleUpdateProductItemSize(selectedProduct, newStockCode)
    },
    shortMessage: '',
  }

  if (sizeDialogState?.type === 'Upgrade') {
    buttonConfig.buttonTitle = GENERAL_SELECT_SIZE
    buttonConfig.title = GENERAL_ADD_TO_BASKET
    buttonConfig.action = async () => {
      const productSlug = selectedProduct?.slug?.replace(
        PRODUCTS_SLUG_PREFIX,
        ''
      )
      const { data: productDetails }: any = await axios.post(NEXT_GET_PRODUCT, {
        slug: productSlug?.startsWith('/')
          ? productSlug?.substring(1)
          : productSlug,
      })
      if (productDetails) {
        let replacementEligibilityDays: Number | null = null
        let returnEligibilityDays: Number | null = null
        const returnEligeble = productDetails?.product?.customAttributes
          ?.find((x: any) => x.key == 'product.returnsandexchange')
          ?.value?.toLowerCase()
        const replacementEligeble = productDetails?.product?.customAttributes
          ?.find((x: any) => x.key == 'product.replacement')
          ?.value?.toLowerCase()

        const replacementEligibility =
          replacementEligeble === 'true' &&
          productDetails?.product?.customAttributes?.find(
            (x: any) => x.key == 'product.replacementeligibility'
          )?.value

        const replacementEligibilityMatch = JSON.stringify(
          replacementEligibility
        )?.match(/\d+/)

        if (replacementEligibilityMatch) {
          const number = parseInt(replacementEligibilityMatch[0], 10)
          replacementEligibilityDays = number
        }

        const returnEligibility =
          returnEligeble === 'true' &&
          productDetails?.product?.customAttributes?.find(
            (x: any) => x.key == 'product.returnandexchangeeligibility'
          )?.value

        const returnEligibilityMatch =
          JSON.stringify(returnEligibility)?.match(/\d+/)

        if (returnEligibilityMatch) {
          const number = parseInt(returnEligibilityMatch[0], 10)
          returnEligibilityDays = number
        }
        const newSize = document
          .querySelector('div.product-sizes div.size-option.border-gray-800')
          ?.attributes.getNamedItem('data-size')?.value
        const variantsThatMatchSize = variantProductFilter(
          variantProducts || [],
          'clothing.size',
          newSize || ''
        )[0]
        const updatedProduct = productDetails?.product?.variantProducts.find(
          (o: any) => o.stockCode === variantsThatMatchSize?.stockCode
        )
        selectedProduct.productId = updatedProduct?.productId
        selectedProduct.stockCode = updatedProduct?.stockCode

        submitData(submitDispatch, CartAction.UPDATE_SIZE)
        try {
          handleEvents(
            selectedProduct,
            trackGoogleAnalyticsEvent,
            user,
            trackMoEngageEvent,
            isChannelStore
          )
          const item = await cartHandler().addToCart(
            {
              basketId: isChannelStore ? storeBasketId : basketId,
              productId:
                selectedProduct?.productId ?? selectedProduct?.recordId,
              qty: 1,
              // manualUnitPrice: selectedProduct?.price.raw.withTax,
              stockCode: selectedProduct?.stockCode,
              userId: user.userId,
              isAssociated: isCartAssociated(cartItems), //user.isAssociated,
              isChannelStore: isChannelStore ? true : false,
              CustomInfo4: JSON.stringify({
                formatted: {
                  title: 'Replacement Eligibility',
                  data: {
                    'Replacement Eligibility':
                      replacementEligibilityDays || null,
                  },
                },
              }),
              CustomInfo5: JSON.stringify({
                formatted: {
                  title: 'Return Eligibility',
                  data: {
                    'Return Eligibility': returnEligibilityDays || null,
                  },
                },
              }),
              CustomInfo4Formatted:
                replacementEligibilityDays?.toString() || '',
              CustomInfo5Formatted: returnEligibilityDays?.toString() || '',
            },
            'ADD',
            { product: selectedProduct },
            (item: any) => {
              setCartItems(item)
              // addToCartGAEvent(trackGoogleAnalyticsEvent, user, selectedProduct)
              setTimeout(() => handleCloseSizeModal(false), SHORT_CLOSE_TIMER)
              resetSubmitData(submitDispatch)
            }
          )
          setCartItems(item)
          addToCartGAEvent(trackGoogleAnalyticsEvent, user, selectedProduct)
          if (
            item?.message &&
            !matchStrings(item?.message, PROD_ADDED_SUCCESSFULLY, true)
          ) {
            setAlert({ type: 'error', msg: item?.message })
          } else {
            setAlertWithLessTimer({ type: 'success', msg: PROD_ADDED })
          }
          await getBasketPromos(isChannelStore ? storeBasketId : basketId)
        } catch (error) {
          setAlert({ type: 'error', msg: NETWORK_ERR })
          resetSubmitData(submitDispatch)
          console.error(':: error ', error)
        }
        return true
      }
    }
  }

  return buttonConfig
}

export const beginCheckout = async (
  cartItems: any,
  user: any,
  trackMoEngageEvent: any,
  preferredPaymentMethod: any
) => {
  const eventId = eventIdForFB()
  const gaPayload = {
    ecommerce: {
      items: cartItems?.lineItems?.map((item: any, itemId: number) => ({
        item_name: item?.name,
        item_brand: item?.brand,
        item_category2: getCategories(item).category2,
        item_category: getCategories(item).category,
        item_variant: item?.colorName,
        color: item?.colorName,
        quantity: item?.qty,
        item_id: item?.sku,
        item_size: JSON.parse(item?.attributesJson)?.Size?.toUpperCase() || '',
        price: item?.price?.raw?.withTax,
        item_var_id: item?.stockCode,
        item_list_name: getCategories(item).category,
        index: itemId,
      })),
      current_page: 'Checkout',
      promotion_name: cartItems?.promotionsApplied
        ?.map((promo: any) => promo?.name)
        ?.join(','),
      promotion_promocode: cartItems?.promotionsApplied
        ?.map((promo: any) => promo?.promoCode)
        ?.join(','),
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      paymode: '',
      address: '',
      event_id: eventId,
      value: cartItems?.grandTotal?.raw?.withTax,
      item_var_id: cartItems?.lineItems[0]?.stockCode,
      ...getKwikPassFields(),
    },
  }
  recordGA4Event(window, GA_EVENT.BEGIN_CHECKOUT, gaPayload)

  const mobileNumber = user?.mobile || user?.telephone
  const deliveryAddress = getDeliveryAddress()
  let facebookeventData: any = {
    data: [
      {
        event_name: FacebookEvents.INITIATE_CHECKOUT,
        event_time: Math.floor(new Date().getTime() / 1000),
        event_id: eventId,
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: deliveryAddress?.city
            ? await getSHA256Hash(
                deliveryAddress?.city?.substring(0, 2)?.toLowerCase()
              )
            : null,
          zp: deliveryAddress?.postCode
            ? await getSHA256Hash(deliveryAddress?.postCode)
            : null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          currency: 'INR',
          num_items: cartItems?.lineItems?.length,
          content_name: getCurrentPage(),
          value: cartItems?.grandTotal?.raw?.withTax,
          content_ids: cartItems?.lineItems?.map(
            (item: any) => item?.stockCode
          ),
          content_type: 'product',
          contents: cartItems?.lineItems?.map((item: any) => ({
            id: item?.productId,
            quantity: item?.qty,
            item_price: item?.price?.raw?.withTax,
            delivery_category: 'home_delivery',
          })),
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)

  let couponStatus = false
  if (cartItems?.promotionsApplied?.length > 0) {
    couponStatus = true
  }
  const begin_checkout = {
    URL: window?.location?.href,
    cart_amount: cartItems?.grandTotal?.raw?.withTax || '',
    cart_quantity: cartItems?.lineItems?.length || '',
    coupon_status: couponStatus,
    coupon_label: cartItems?.promotionsApplied[0]?.promoCode || '',
    Product_name: cartItems?.lineItems?.map((item: any) => item?.name || ''),
    Product_ID: cartItems?.lineItems?.map((item: any) => item?.stockCode || ''),
    Price: cartItems?.lineItems?.map(
      (item: any) => item?.price?.raw?.withTax || ''
    ),
    Product_color: cartItems?.lineItems?.map(
      (item: any) => item.colorName || ''
    ),
    Product_category_2: cartItems?.lineItems?.map(
      (item: any) => item?.categoryItems[0]?.categoryName || ''
    ),
    Product_category: cartItems?.lineItems?.map((item: any) =>
      item?.categoryItems.length
        ? item?.categoryItems[item?.categoryItems?.length - 1]?.categoryName
        : ''
    ),
    Quantity: cartItems?.lineItems?.map((item: any) => item?.qty || ''),
    Product_size: cartItems?.lineItems?.map(
      (item: any) =>
        item?.variantProducts
          ?.find(
            (variantProductDatum: any) =>
              variantProductDatum?.stockCode === item?.stockCode
          )
          ?.variantAttributes?.find(
            (variantAttributeDatum: any) =>
              variantAttributeDatum?.fieldCode === 'clothing.size'
          )?.fieldValue || ''
    ),
  }
  trackMoEngageEvent(MO_ENGAGE_EVENT.BEGIN_CHECKOUT, begin_checkout, window)
}

export const mapShippingPlansToItems = (plans?: any, items?: any) => {
  const itemsClone = [...items]
  return plans?.reduce((acc: any, obj: any) => {
    acc?.forEach((cartItem: any) => {
      const foundShippingPlan = obj.items.find((item: any) => {
        return (
          item?.productId?.toLowerCase() === cartItem?.productId?.toLowerCase()
        )
      })
      if (foundShippingPlan) {
        cartItem.shippingPlan = obj
      }
    })
    return acc
  }, itemsClone)
}

export const fetchShippingPlans = async (props: IFetchShippingPlans) => {
  const { cart, setCartItems, isChannelStore, storeBasketId, basketId } = props

  const shippingMethodItem: any = cart?.shippingMethods?.find(
    (method: any) => method?.id === cart?.shippingMethodId
  )

  if (shippingMethodItem) {
    const model = {
      BasketId: isChannelStore ? storeBasketId : basketId,
      OrderId: '00000000-0000-0000-0000-000000000000',
      PostCode: '',
      ShippingMethodType: shippingMethodItem?.type,
      ShippingMethodId: cart?.shippingMethodId,
      ShippingMethodName: shippingMethodItem?.displayName,
      ShippingMethodCode: shippingMethodItem?.shippingCode,
      DeliveryItems: cart?.lineItems?.map((item: any) => {
        return {
          BasketLineId: Number(item?.id),
          OrderLineRecordId: '00000000-0000-0000-0000-000000000000',
          ProductId: item?.productId,
          ParentProductId: item?.parentProductId,
          StockCode: item?.stockCode,
          Qty: item?.qty,
          PoolCode: item?.poolCode || null,
        }
      }),
      AllowPartialOrderDelivery: true,
      AllowPartialLineDelivery: true,
      PickupStoreId: '00000000-0000-0000-0000-000000000000',
      RefStoreId: null,
      PrimaryInventoryPool: 'PrimaryInvPool',
      SecondaryInventoryPool: 'PrimaryInvPool',
      IsEditOrder: false,
      OrderNo: null,
      DeliveryCenter: null,
    }

    // If OMS is enabled
    if (BETTERCOMMERCE_OMS_ENABLED) {
      const response = await axios.post(NEXT_SHIPPING_PLANS, { model })
      setCartItems({
        ...cart,
        lineItems: mapShippingPlansToItems(response.data, cart.lineItems),
      })
    }
  }
}

export const handleViewCartGAEvents = (
  cartItems: any,
  deviceCheck: any,
  user: any,
  previousPath: string
) => {
  const clickSource = localStorage?.getItem('isFromCartIcon')
  recordGA4Event(window, GA_EVENT.VIEW_CART, {
    ecommerce: {
      items: cartItems?.lineItems?.map((items: any, itemId: number) => ({
        item_name: items?.name,
        item_id: items?.sku,
        price: items?.price?.raw?.withTax,
        item_brand: items?.brand,
        item_category2: getCategories(items).category2,
        item_category: getCategories(items).category,
        item_variant: items?.colorName,
        item_list_name: getCategories(items).category2,
        item_list_id: '',
        index: itemId,
        quantity: items?.qty,
        item_var_id: items?.stockCode,
      })),
      device: deviceCheck,
      current_page: getCurrentPage(previousPath),
      clicked_source: clickSource != '' ? clickSource : 'add_to_cart',
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    },
  })
}

export const fetchSavedUPIs = async (customerId: string) => {
  try {
    const { data }: any = await axios.post(NEXT_GET_JUSPAY_SAVED_UPIS, {
      customerId,
      upiId: EmptyGuid,
    })
    if (
      USER_TOKEN_ERROR?.includes(data?.error?.toLowerCase()) &&
      !Cookies.get('askForLoginLogout')
    ) {
      Cookies.set('askForLoginLogout', 'true')
      location?.reload()
    }
    return data?.result
  } catch (error) {
    console.error(':: error ', error)
  }
  return []
}

export const calculateLastOrderRecords = (ordersResult: any) => {
  let countTodayOrder = 0
  let countWeekOrder = 0
  let countMonthOrder = 0
  ordersResult?.forEach((item: any) => {
    if (item?.orderType === 12 && item?.orderStatus === 'Delivered') {
      const _d = new Date(item?.orderDate + 'Z').toLocaleString('en-US')
      const __d = new Date().toLocaleString('en-US')
      const orderDate: any = Date.parse(_d)
      const currentDate: any = Date.parse(__d)
      const diffTime = currentDate - orderDate
      const diffSeconds = diffTime / 1000
      if (diffSeconds < 86400) {
        countTodayOrder++
        countWeekOrder++
        countMonthOrder++
      } else if (diffSeconds > 86400 && diffSeconds < 604800) {
        countWeekOrder++
        countMonthOrder++
      } else if (diffSeconds > 604800 && diffSeconds < 2592000) {
        countMonthOrder++
        //do nothing
      }
    }
  })
  return {
    countTodayOrder,
    countWeekOrder,
    countMonthOrder,
  }
}

export const generatePriceDetailsSection = (
  props: IGeneratePriceDetailsSection
) => {
  const {
    totalMRP,
    discountOnMRP,
    cartItems,
    freeItem,
    setConvenienceFee,
    convenienceData,
    isChannelStore,
  } = props

  const isCouponDiscountShow = () => {
    let storeDetails = {
      id: null,
      name: null,
      customInfo1: '',
    }
    if (isChannelStore) {
      let addressInfo: any = ''
      addressInfo = Cookies?.get('storeDetails')
      storeDetails = addressInfo && JSON.parse(addressInfo)
    }
    const isCouponsAllowed =
      isChannelStore &&
      storeDetails?.customInfo1?.toLowerCase() === 'no coupons'
        ? false
        : true
    if (!isCouponsAllowed && cartItems?.promotionsApplied?.length <= 0) {
      return false
    } else if (cartItems?.promotionsApplied?.length > 0) {
      if (cartItems?.discount?.raw?.withTax > 0) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <>
      <div className="flex flex-col mb-4">
        <h3 className="text-base font-semibold text-black">
          {GENERAL_PRICE_DETAIL}
        </h3>
        <p className="text-xs font-light text-gray-500">
          {GENERAL_PRICE_INC_TAX}
        </p>
      </div>
      <div className="flex justify-between py-1 text-gray-900 font-small">
        <p className="text-xs font-light text-secondary-full-opacity">
          Total MRP
        </p>
        <p className="text-xs font-medium text-secondary-full-opacity">
          {priceFormat(totalMRP)}
        </p>
      </div>
      {discountOnMRP > 0 && (
        <div className="flex justify-between py-1 text-gray-900 font-small">
          <p className="text-xs font-light text-secondary-full-opacity">
            Discount on MRP
          </p>
          <p className="text-xs font-medium text-green-dark">
            -{priceFormat(discountOnMRP)}
          </p>
        </div>
      )}
      {isCouponDiscountShow() && (
        <div className="flex justify-between py-1 text-gray-900 font-small">
          <p className="text-xs font-light text-secondary-full-opacity">
            Coupon Discount
          </p>
          {cartItems.promotionsApplied?.length > 0 ? (
            <div className="">
              {cartItems.discount?.raw?.withTax > 0 && (
                <p className="text-xs font-medium text-green-dark">
                  -{priceFormat(cartItems.discount?.raw?.withTax)}
                </p>
              )}
            </div>
          ) : (
            <p
              className="text-xs font-medium text-orange-500 cursor-pointer"
              onClick={() => {
                Router?.push(
                  { pathname: `${Router?.asPath}#couponopen` },
                  `${Router?.asPath}#couponopen`,
                  { shallow: true, scroll: false }
                )
              }}
            >
              Apply Coupon
            </p>
          )}
        </div>
      )}
      {cartItems?.additionalCharge?.raw?.withTax > 0 &&
        convenienceData?.title && (
          <div className="flex justify-between py-1 text-gray-900 font-small">
            <p className="flex items-center text-xs font-normal text-gray-900">
              {convenienceData?.title}
              <span
                className="ml-[5px] cursor-pointer"
                onClick={() => {
                  setConvenienceFee(true)
                }}
              >
                <InfoIcon
                  width={12}
                  height={12}
                  pathFillColor="rgba(0, 19, 37, 0.92)"
                />
              </span>
            </p>
            <p className={`text-xs font-medium text-black`}>
              {priceFormat(cartItems?.additionalCharge?.raw?.withTax)}
            </p>
          </div>
        )}
      {!props?.isChannelStore ? (
        <div className="flex justify-between py-1 text-gray-900 font-small">
          <p className="text-xs font-normal text-secondary-full-opacity">
            {GENERAL_SHIPPING}
          </p>
          {cartItems.shippingCharge?.raw?.withTax > 0 ? (
            <p className="text-xs font-medium text-secondary-full-opacity">
              {priceFormat(cartItems.shippingCharge?.raw?.withTax)}
            </p>
          ) : (
            <p className="">
              <span className="text-xs font-medium text-secondary-full-opacity line-through">
                {priceFormat(STATIC_DELIVERY_FEE)}
              </span>
              <span className="text-xs font-medium text-green">
                {' '}
                {FREE_SHIPPING}
              </span>
            </p>
          )}
        </div>
      ) : null}
      <div className="flex justify-between py-4 mt-3 text-gray-900 border-t border-gray-200 border-dashed font-small">
        <p className="text-base font-medium text-secondary-full-opacity">
          You pay
        </p>
        <p className="text-base font-medium text-secondary-full-opacity">
          {priceFormat(cartItems?.grandTotal?.raw?.withTax)}
        </p>
      </div>
      {freeItem ? (
        <div className="flex info-text text-sm font-normal">
          <span>
            <i className="align-middle sprite-icon sprite-blue-info mt-0.5"></i>
          </span>
          <span className="pl-2">
            All items in this order are EXCHANGE ONLY, because this order
            contains a free gift item
          </span>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export const ppgConfirmOrder = (
  trackGoogleAnalyticsEvent: any,
  previousPath: string,
  user: any
) => {
  trackGoogleAnalyticsEvent(
    GA_EVENT.PPG_ORDER_CONFIRM,
    {
      previous_page: previousPath,
      current_page: getCurrentPage(),
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    },
    window
  )
}

export const addToCartGAEventPPG = async (
  trackGoogleAnalyticsEvent: any,
  user: any,
  productData: any,
  isChannelStore: boolean,
  previousPath: string
) => {
  const eventId = eventIdForFB()
  const cartItem = [
    {
      item_name: productData?.name,
      item_brand: productData?.brand,
      item_category2: getCategories(productData).category2,
      item_category: getCategories(productData).category,
      item_variant: getColorAndSize(productData),
      color: getColor(productData),
      quantity: productData?.qty,
      item_id: productData?.sku,
      item_size: getlineItemSizeWithoutSlug(productData),
      price: productData?.price?.raw?.withTax,
      item_var_id: productData?.stockCode,
      item_list_name: getCategories(productData).category,
      index: 1,
    },
  ]
  if (isChannelStore) {
    trackGoogleAnalyticsEvent(
      GA_EVENT.PPG_ADD_TO_CART,
      {
        previous_page: previousPath,
        current_page: getCurrentPage(),
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        event_id: eventId,
        items: cartItem,
      },
      window
    )
  } else {
    trackGoogleAnalyticsEvent(
      GA_EVENT.ADD_TO_CART,
      {
        ecommerce: {
          items: cartItem,
          cart_quantity: 1,
          total_value: productData?.price?.raw?.withTax,
          current_page: getCurrentPage(),
          section_title: 'Product Detail',
          event_id: eventId,
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        },
      },
      window
    )
  }

  const mobileNumber = user?.mobile || user?.telephone
  let facebookeventData = {
    data: [
      {
        event_name: FacebookEvents.ADD_TO_CART,
        event_time: Math.floor(new Date().getTime() / 1000),
        event_id: eventId,
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: null,
          zp: null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          currency: 'INR',
          num_items: 1,
          content_name: getCurrentPage(),
          value: productData?.price?.raw?.withTax,
          content_category: getCategories(productData).category,
          content_ids: [productData?.stockCode],
          content_type: 'product',
          contents: [
            {
              id: productData?.recordId ?? productData?.productId,
              quantity: 1,
              item_price: productData?.price?.raw?.withTax,
              delivery_category: 'home_delivery',
            },
          ],
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)
}

export const getShippingInfoPayload = (
  user: any,
  cartItems: any,
  prevPage: string,
  addressIdx?: any
) => {
  const mobileRSA = getEncryptedNumber(getUserNumber(user));

  return {
    ecommerce: {
      shipping_tier: cartItems?.shippingMethods?.[0]?.countryCode,
      coupon: cartItems?.promotionsApplied?.length
        ? cartItems?.promotionsApplied?.map((x: any) => x?.promoCode)?.join(",")
        : "",
      value: cartItems?.subTotal?.raw?.withTax,
      items: cartItems?.lineItems?.map((item: any) => ({
        item_name: item?.name,
        price: item?.price?.raw?.withTax,
        quantity: item?.qty,
        item_id: item?.sku,
        item_var_id: item?.stockCode,
        item_size: item?.variantProducts
          ?.find((x: any) => x?.stockCode === item?.stockCode)
          ?.variantAttributes?.find((x: any) => x?.fieldCode === "clothing.size")
          ?.fieldValue,
        item_brand: item?.brand,
        item_variant: item?.colorName,
      })),
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      address_source: getAddressSource(),
      previous_page: prevPage,
      promotion_name: cartItems?.promotionsApplied
        ?.map((promo: any) => promo?.name)
        ?.join(","),
      promotion_promocode: cartItems?.promotionsApplied
        ?.map((promo: any) => promo?.promoCode)
        ?.join(","),
      address_rank: addressIdx,
      mobile_number: mobileRSA,
      ...getKwikPassFields(),
    },
  };
};

export const getPaymentFailurePayload = (cartItems:any) => {
  return {
    coupon: cartItems?.promotionsApplied?.length
      ? cartItems?.promotionsApplied?.map((x: any) => x?.promoCode)?.join(',')
      : '',
    payment_type: 'credit-card',
    payment_type_variant:
      'paytm/phonepe/hdfc bank/ icici bank/mastercard/visa',
    card_type: 'debit/credit',
    bank_name: 'icici/hdfc/state bank of india',
    value: '500',
    loggedin_status: 'true/false',
    enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
  }
}